import request from "../utils/request";

export function getProjectList(data) {
  return request({
    url: '/api/energy/list',
    method: 'get',
    params:data
  })
}


//删除项目
export function delProject(data) {
  return request({
    url: '/api/energy/del',
    method: 'get',
    params:data
  })
}


//添加项目
export function storeProject(data) {
  return request({
    url: '/api/energy/store',
    method: 'post',
    data:data
  })
}

//项目表格
export function editTableProject(data){
  return request({
    url: '/api/energy/edit',
    method: 'post',
    data:data
  })
}

//获得项目表格信息
export function projectTableInfo(data){
  return request({
    url: '/api/energy/info',
    method: 'get',
    params:data
  })
}


//生成批次
export function addBatch(data){
  return request({
    url: '/api/energy/batch',
    method: 'post',
    data:data
  })
}

//形审保存
export function checkSave(data){
  return request({
    url: '/api/energy/check_save',
    method: 'post',
    data:data
  })
}

//形审通过
export function checkPass(data){
  return request({
    url: '/api/energy/check_pass',
    method: 'post',
    data:data
  })
}

//形审/技术打会
export function checkBack(data){
  return request({
    url: '/api/energy/check_back',
    method: 'post',
    data:data
  })
}

//访问文件
export function getFilesBlob(url){
  return request({
    method: 'get',
    responseType: 'blob', // 设置响应文件格式
    url: url
  })
}

//分配专家
export function distributionExport(data){
  return request({
    url: '/api/energy/distribution',
    method: 'post',
    data:data
  })
}

//获得专家
export function exportList(data){
  return request({
    url: '/api/energy/expert',
    method: 'get',
    params:data
  })
}

//任务发送
export function sendTask(data){
  return request({
    url: '/api/energy/send',
    method: 'post',
    data:data
  })
}
//分配专家
export function sendexport(data){
  return request({
    url: '/api/energy/fexport',
    method: 'get',
    params:data,
    responseType: 'blob',
  })
}

//技术审查
export function skillSave(data){
  return request({
    url: '/api/energy/skill_save',
    method: 'post',
    data:data
  })
}

//返回项目给专家
export function skillBack(data){
  return request({
    url: '/api/energy/skill_back',
    method: 'post',
    data:data
  })
}

//专家评审表
//返回项目给专家
export function saveReport(data){
  return request({
    url: '/api/energy/report',
    method: 'post',
    data:data
  })
}

//改变项目状态
export function changeStatus(data){
  return request({
    url: '/api/energy/change_status',
    method: 'post',
    data:data
  })
}

//填写寄送地址
export function setAddress(data){
  return request({
    url: '/api/energy/address',
    method: 'post',
    data:data
  })
}


//填写寄送地址
export function exportMake(data){
  return request({
    url: '/api/energy/export',
    method: 'post',
    data:data
  })
}



//添加员工
export function staffStoreApi(data){
  return request({
    url: '/api/energy/staff_store',
    method: 'post',
    data:data
  })
}

//员工列表
export function staffListApi(data){
  return request({
    url: '/api/energy/staff',
    method: 'get',
    params:data
  })
}


//员工信息
export function staffInfoApi(data){
  return request({
    url: '/api/energy/staff_info',
    method: 'get',
    params:data
  })
}

//删除员工
export function staffDelApi(data){
  return request({
    url: '/api/energy/staff_del',
    method: 'get',
    params:data
  })
}

//获取子账户
export function getChildApi(data){
  return request({
    url: '/api/energy/child',
    method: 'get',
    params:data
  })
}

//增加子账户
export function addChildApi(data){
  return request({
    url: '/api/energy/add_child',
    method: 'post',
    data:data
  })
}

//编辑自己
export function editSelfApi(data){
  return request({
    url: '/api/energy/edit_self',
    method: 'post',
    data:data
  })
}

//获得主账号信息
export function userInfoApi(data){
  return request({
    url: '/api/user/info',
    method: 'get',
    params:data
  })
}

//获得专家信息
export function pInfoApi(data){
  return request({
    url: '/api/energy/pinfo',
    method: 'get',
    params: data,
  })
}

//编辑专家信息
export function pEditApi(data){
  return request({
    url: '/api/energy/pedit',
    method: 'post',
    data: data,
  })
}

//获得专家列表
export function zplistApi(data){
  return request({
    url: '/api/energy/zplist',
    method: 'get',
    params: data,
  })
}

//添加账户
export function pStoreApi(data){
  return request({
    url: '/api/energy/pstore',
    method: 'post',
    data: data,
  })
}


//重置密码
export function resetApi(data){
  return request({
    url: '/api/energy/reset',
    method: 'get',
    params: data,
  })
}

//获得地区图表信息
export function dataApi(data){
  return request({
    url: '/api/energy/data',
    method: 'get',
    params: data,
  })
}

//获得地区年度图表信息
export function dataYearApi(data){
  return request({
    url: '/api/energy/year',
    method: 'get',
    params: data,
  })
}

//获得星级
export function starsApi(data){
  return request({
    url: '/api/energy/stars',
    method: 'get',
    params: data,
  })
}


//获得星级
export function companyApi(data){
  return request({
    url: '/api/energy/company',
    method: 'get',
    params: data,
  })
}


//导出数据 energy/tong
export function exportDataApi(data){
  return request({
    url: '/api/energy/tong',
    method: 'get',
    params: data,
  })
}

//地级市账号
export function cityAccount(data){
  return request({
    url: '/api/energy/cities',
    method: 'get',
    params: data,
  })
}

// 寄取状态判断
export function changeQuApi(data){
  return request({
    url: '/api/energy/qu',
    method: 'get',
    params: data,
  })
}